import events from '@layers/web/constants/events.js'

/**
 * For list of events
 * @see https://ads.tiktok.com/help/article/standard-events-parameters
 */
function initTT () {
  const { public: { ttId } } = useRuntimeConfig()
  const router = useRouter()

  if(!ttId) {
    return
  }

  /* eslint-disable */
  !(function (w, d, t) {
    w.TiktokAnalyticsObject = t
    const ttq = w[t] = w[t] || []
    ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'],
    ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }
    for (let i = 0; i < ttq.methods.length; i++) { ttq.setAndDefer(ttq, ttq.methods[i]) }
    ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) { ttq.setAndDefer(e, ttq.methods[n]) }
      return e
    }, ttq.load = function (e, n) {
      const i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
      ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date(), ttq._o = ttq._o || {}, ttq._o[e] = n || {}
      const o = document.createElement('script')
      o.type = 'text/javascript', o.async = !0, o.src = i + '?sdkid=' + e + '&lib=' + t
      const a = document.getElementsByTagName('script')[0]
      a.parentNode.insertBefore(o, a)
    }
    ttq.load(ttId)
    ttq.enableCookie()
  }(window, document, 'ttq'))
  /* eslint-enable */

  router.afterEach(() => {
    if (/ destination_|tel=/.test(window.location.href)) {
      return
    }
    try {
      window.ttq.page()
    } catch {
      // No ttq on window
    }
  })
}

export default defineNuxtPlugin(() => {
  const { userConsentStatus } = useUserConsent()
  if (userConsentStatus.value.advertising) {
    initTT()
  }

  window.addEventListener(events.rbcsChange, (e) => {
    if (!e.detail.userConsentStatus.advertising) {
      return
    }

    initTT()
  })
})
