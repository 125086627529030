// Migrated
<template lang="pug">
a.d-flex.text-black.tripsuggestion.py-1.w-100(
  :class="{ charter: trip.charter }"
)
  .col-1.m-auto.text-center
    fa(:icon="trip.flight_trip ? 'plane' : 'bus'")
  .col-7.col-lg-8
    .font-weight-bold {{ trip.title }}
    .text-truncate.text-smaller {{ trip.subtitle }}
    .d-inline-flex.text-smaller.flex-wrap(
      v-if="!trip.charter || trip.departures"
    )
      .mr-3(v-if="trip.departures", data-i18n="departuresText") {{ $t('departuresText', trip.departures) }}
      div(v-if="!trip.charter", data-i18n="daysText") {{ $t('daysText', {n: days(trip) } ) }}
  .col-4.col-lg-3.text-right.m-auto
    template(v-if="price")
      small(data-i18n="shortFromPrice") {{ $t('shortFromPrice') }}
      span.text-nowrap &nbsp;{{ $n(price) }}&nbsp;
</template>

<script>
import DaysMixin from '@layers/web/mixins/days.mixin'

export default defineNuxtComponent({
  mixins: [
    DaysMixin
  ],

  props: {
    trip: {
      type: Object,
      required: true
    }
  },

  computed: {
    price () {
      return this.trip.price || this.trip.price_min
    }
  }
})
</script>
