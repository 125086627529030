import events from '@layers/web/constants/events.js'

/**
 * For list of events
 * @see https://developers.facebook.com/docs/meta-pixel/reference#events
 */
function initFB () {
  const { public: { fbId } } = useRuntimeConfig()
  const router = useRouter()

  if(!fbId) {
    return
  }

  /* eslint-disable */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');

  fbq.disablePushState = true
  fbq('init', fbId)
  /* eslint-enable */

  router.afterEach(() => {
    if (/ destination_|tel=/.test(window.location.href)) {
      return
    }
    try {
      window.fbq('trackCustom', 'VirtualPageView', { url: window.location.href })
    } catch {
      // No fbq on window
    }
  })
}

export default defineNuxtPlugin(() => {
  const { userConsentStatus } = useUserConsent()
  if (userConsentStatus.value.advertising) {
    initFB()
  }

  window.addEventListener(events.rbcsChange, (e) => {
    if (!e.detail.userConsentStatus.advertising) {
      return
    }

    initFB()
  })
})
