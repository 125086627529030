import dayjs from 'dayjs'
import expirations from '@packages/cache/expirations'

export const useRootStore = defineStore('root', {
  state: () => ({
    tripTypesSidebar: [],
    topNavbar: [],
    footerLinks: [],
    startCalendar: [],
    startCategories: [],
    charterAirports: [],
    charterDestinations: [],
    charterAirportNames: {},
    pageBannerTitle: '',
    pageBannerSubtitle: '',
    pageBannerSubtitleColor: '',
    pageBannerType: '',
    pageBannerLink: '',
    pageBannerLinkButton: '',
    /**
     * @property {object} pageBannerBackground
     * {
     *   picture_cloudinary: string
     *   class: string (banner layout)
     *   align: string
     *   image: {
     *     type: @see hotel-images.mixins.js getProviderImage
     *     source: string
     *   } (banner layout)
     *   video: {
     *     source: string
     *     type: @see "components/common/MediaPlayer" src
     *     poster: string
     *   } (banner layout)
     * }
     */
    pageBannerBackground: {},
    pageBannerStars: {},
    pageBannerPrice: null,
    pageBannerSpecial: {},
    bannerPriceWithoutDiscount: null,
    pageBannerDays: null,
    pageGradientMask: true,
    pageExtraFooter: null,
    showOfficeTool: false,
    showTranslationTool: false,
    showAgentTools: false,
    showCmsTools: false,
    agentCookie: false,
    showAgentCalls: false,
    rolfsCookie: null,
    alert: {},
    localUrl: null,

    gtmBootstrapped: false,
    selectedAirportIata: null,

    modalActive: false,

    newsletterAirports: [],
    weather: null,
    event: null,
    previousRoute: null,

  }),
  persist: [
    {
      paths: ['selectedAirportIata'],
      storage: persistedState.localStorage
    },
    {
      paths: ['charterAirportNames'],
      storage: persistedState.localStorageWithExpiration({
        expiration: expirations.longDuration
      })
    }
  ],
  getters: {
    shouldShowAgentCalls: state => !!state.showAgentCalls,
    showOfficeTools: state => state?.showOfficeTool,
    doShowTranslationTools: state => state?.showTranslationTool,

    showTranslationTools(state){
      const localeStore = useLocaleStore()
      return state?.showTranslationTool && localeStore.translationToolsActive
    },

    getDestinationWeather: state => (destination) => {
      if (!state.weather) {
        return null
      }

      return state.weather[destination]
    },

    getOgImage(state) {
      const { public: { images: imagesConfig } } = useRuntimeConfig()
      return state.pageBannerBackground?.image?.url || state.pageBannerBackground?.picture_cloudinary || imagesConfig.fallbackHeroId
    },
  },
  actions: {
    //old mutations
    SET_URL (url) {
      this.localUrl = url
    },

    SET_TRIP_TYPES (types) {
      this.tripTypesSidebar = types
    },

    SET_TOP_NAVBAR (data) {
      this.topNavbar = data
    },

    SET_FOOTER_LINKS (data) {
      this.footerLinks = data
    },

    SET_PAGE_BANNER (data) {
      this.pageBannerTitle = data.title
      this.pageBannerSubtitle = data.subtitle
      this.pageBannerSubtitleColor = data.subtitleColor
      this.pageBannerSubtitleWeight = data.subtitleWeight
      this.pageBannerBackground = data.background
      this.pageBannerType = data.type
      this.pageBannerLink = data.link
      this.pageBannerLinkButton = data.linkButton
      this.pageBannerStars = data.stars
      this.pageBannerPrice = data.price
      this.pageBannerDays = data.days
      this.pageBannerSpecial = data.special
      this.bannerPriceWithoutDiscount = data.priceWithoutDiscount
    },

    SET_PAGE_BANNER_TITLE (data) {
      this.pageBannerTitle = data
    },

    SET_PAGE_BANNER_STARS (data) {
      this.pageBannerStars = data
    },

    SET_PAGE_BANNER_SUBTITLE (data) {
      this.pageBannerSubtitle = data
    },

    SET_PAGE_BANNER_SUBTITLE_COLOR (data) {
      this.pageBannerSubtitleColor = data
    },

    SET_PAGE_BANNERTYPE (data) {
      this.pageBannerType = data
    },

    SET_PAGE_BANNER_BACKGROUND (data) {
      this.pageBannerBackground = data
    },

    SET_PAGE_BANNER_LINK (link) {
      this.pageBannerLink = link
    },

    SET_PAGE_BANNER_LINK_BUTTON (link) {
      this.pageBannerLinkButton = link
    },

    SET_PAGE_BANNER_DAYS (days) {
      this.pageBannerDays = days
    },

    SET_PAGE_BANNER_PRICE (price) {
      this.pageBannerPrice = price
    },

    SET_PAGE_GRADIENT_MASK (data) {
      this.pageGradientMask = data
    },

    SET_PAGE_EXTRA_FOOTER (data) {
      this.pageExtraFooter = data
    },

    SET_START_CALENDAR (data) {
      this.startCalendar = data
    },

    SET_START_CATEGORIES (data) {
      this.startCategories = data
    },

    SET_CHARTER_AIRPORTS (data) {
      this.charterAirports = data
    },

    SET_CHARTER_AIRPORT_NAMES (data) {
      this.charterAirportNames = data
    },

    SET_CHARTER_DESTINATIONS (data) {
      this.charterDestinations = data
    },

    SET_SHOWOFFICETOOLS (data) {
      this.showOfficeTool = data
    },

    SET_SHOWAGENTTOOLS (data) {
      this.showAgentTools = data
    },

    SET_AGENTCOOKIE (data) {
      this.agentCookie = data
    },

    SET_SHOWAGENTCALLS (data) {
      this.showAgentCalls = data
    },

    SET_ROLFSCOOKIE (data) {
      this.rolfsCookie = data
    },

    SET_ALERT (data) {
      this.alert = data
    },

    SET_GTM (data) {
      this.gtmBootstrapped = data
    },

    SET_NL (data) {
      this.newsletterAirports = data
    },

    SET_WEATHER (data) {
      this.weather = data
    },

    SET_EVENT (data) {
      this.event = data
    },

    SET_TRANSLATIONTOOLS (data) {
      this.showTranslationTool = data
      this.showTranslationTools
    },

    SET_CMSTOOLS (data) {
      this.cmsTools = data
    },

    SET_SELECTED_AIRPORT (airport) {
      this.selectedAirportIata = airport
    },

    SET_MODAL_ACTIVE (value) {
      this.modalActive = value
    },



    //old actions
    async handleAgentCookie(agentCode) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (agentCode === 'clean') {
        const deleteCookie = useCookie('a')
        deleteCookie.value = null

        this.SET_SHOWOFFICETOOLS(false)
        this.SET_SHOWAGENTTOOLS(false)
        this.SET_TRANSLATIONTOOLS(false)
        this.SET_CMSTOOLS(false)
        this.SET_AGENTCOOKIE(false)
      } else if (agentCode) {
        try {
          const { data: result } = await apiFetch(`/${locale}/reseller/${agentCode}`)

          const cookie = {
            code: agentCode,
            currentDate: Date.now(),
            maxAge: result.cookietime,
            officetools: result.show_officetools,
            agenttools: result.show_agenttools ? agentCode : false,
            translationtools: result?.translations_tools || false,
            cmstools: result.cms_tools || false
          }
          try {
            const delCookie = useCookie('a')
            delCookie.value = null

            const cookieOpts = {
              maxAge: result.cookietime,
              path: '/'
            }

            const a = useCookie('a', {
              ...cookieOpts
            })
            a.value = {
              ...cookie
            }
          } catch (e) {
            // Cannot set headers after they are sent to the client
          }
          this.SET_SHOWOFFICETOOLS(cookie.officetools)
          this.SET_SHOWAGENTTOOLS(cookie.agenttools)
          this.SET_TRANSLATIONTOOLS(cookie.translationtools)
          this.SET_CMSTOOLS(cookie.cmstools)
          this.SET_AGENTCOOKIE(cookie)
        } catch (e) {
          const { $sentryCaptureMessage } = useNuxtApp()

          if (typeof $sentryCaptureMessage === 'function') {
            $sentryCaptureMessage('Failed to get agentCookie', {
              level: 'info',
              contexts: {
                details: {
                  id: agentCode
                }
              },
              tags: {
                type: 'UX'
              }
            })
          }
        }
      } else {
        const a = useCookie('a')
        if (a.value) {
          this.SET_SHOWOFFICETOOLS(a.value.officetools)
          this.SET_SHOWAGENTTOOLS(a.value.agenttools)
          this.SET_TRANSLATIONTOOLS(a.value.translationtools)
          this.SET_CMSTOOLS(a.value.cmstools)
          this.SET_AGENTCOOKIE(a.value)
        }
      }
    },

    async fetchAgentCall () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      this.SET_SHOWAGENTCALLS(null)

      if (!this.showOfficeTools) {
        return
      }

      try {
        const { data: result } = await apiFetch(`/${locale}/current-calls-for-agent`)

        if (!result.error) {
          this.SET_SHOWAGENTCALLS({
            ...result,
            time: dayjs().unix()
          })
        }
      } catch {
        // do nothing
      }
    },

    async fetchStartCalendar () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.startCalendar.length > 0) {
        return
      }

      const { data: res } = await apiFetch(`/${locale}/calendar?hide_soldout=1`)

      this.SET_START_CALENDAR(res.departures)
    },

    async fetchStartCategories () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/categories-start`)

      this.SET_START_CATEGORIES(res)
    },

    async fetchCharterAirports () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.charterAirports.length > 0) {
        return
      }

      const { data: res } = await apiFetch(`/${locale}/charter/departure-airports`)
      this.SET_CHARTER_AIRPORTS(res)
    },

    async fetchCharterDestinations () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.charterDestinations.length > 0) {
        return this.charterDestinations
      }

      const { data: result } = await apiFetch(`/${locale}/charter/avalible-destinations`)

      this.SET_CHARTER_DESTINATIONS(result)

      return result
    },

    async fetchTripTypesSidebar (server = true) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if(!server) {
        const { data: res } = await apiFetch(`/${locale}/sidebar`, {lazy: true}, true)
        this.SET_TRIP_TYPES(res)

        return res
      }else{
        const { data: res } = await apiFetch(`/${locale}/sidebar`)
        this.SET_TRIP_TYPES(res)

        return res
      }
    },

    async fetchTopNavbar () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/topnavbar`)
      const formatted = res.map(item => ({
        ...item,
        label: item.label ? item.label.replace('\r', '\n') : item.label,
        submenu: Array.isArray(item.submenu) ? item.submenu.map(subItem => ({
          ...subItem,
          label: subItem.label ? subItem.label.replace('\r', '\n') : subItem.label
        })) : item.submenu
      }))

      this.SET_TOP_NAVBAR(formatted)
    },

    async fetchFooter () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/footer-links`)

      this.SET_FOOTER_LINKS(res)
    },

    async fetchAlert () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/alert`)

      this.SET_ALERT(res)
    },

    async fetchHeroImage ({ page, onlyFetch }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { public: { images: imagesConfig } } = useRuntimeConfig()

      let { data: res } = await apiFetch(`/${locale}/heroimage?page=${page}`, {}, false )

      // branding aware fallback, and dynamically set
      if (res?.picture_cloudinary === 'hero/rome_sqg4ot') {
        res = {
          ...res,
          picture_cloudinary: imagesConfig.fallbackHeroId
        }
      }

      if (!onlyFetch) {
        this.SET_PAGE_BANNER_BACKGROUND(res)
      }

      return res
    },

    async fetchNewsletterLists () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/newsletter-lists`)

      this.SET_NL(res)
      return res
    },

    async fetchWeather () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.weather) {
        return
      }

      const { data: res } = await apiFetch(`/${locale}/charter/weather`)

      this.SET_WEATHER(res)
    },

    async fetchSingleEvent ({ slug }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/event/${slug}`)

      this.SET_EVENT(res)

      return res
    },

    async fetchUpcomingEvents () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: result } = await apiFetch(`/${locale}/event-list`)
      return result
    },

    async registerForEvent ({ id, email, name, pax }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      let res
      try {
        const { data: result } = await apiFetch(`/${locale}/event-register`, {
          method: 'POST',
          body: {
            event_id: id,
            email,
            name,
            number_pax: pax
          }
        })
        res = result
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
      }

      return res
    },

    async listAllTourleaders () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: result } = await apiFetch(`/${locale}/tourleaders`)
      return result
    },

    async listAllInfoPages () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: result } = await apiFetch(`/${locale}/info-pages`)
      return result
    },

    setSelectedAirport (airport) {
      this.SET_SELECTED_AIRPORT(airport)
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot))
}