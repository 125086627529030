
export const localeEnv = (envKey: string) => (
  (process.env.locale && process.env[`${envKey}_${process.env.locale.toUpperCase()}`]) ??
  process.env[envKey]
)

export const localeToLangInfo = (locale: string) => {
  switch (locale) {
    case 'no':
      return {
        code: locale,
        lang: 'nb',
        name: 'Norsk',
        iso: 'nb-NO'
      }
    default:
      return {
        code: locale,
        lang: 'sv',
        name: 'Svenska',
        iso: 'sv-SE'
      }
  }
}

export const localeToLang = (locale: string) => localeToLangInfo(locale).lang
