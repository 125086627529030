<template lang="pug">
NuxtLoadingIndicator(:color="loadingColor")
.position-relative(:class="wrapperClass")
  App
    slot
</template>

<script setup>

useConfigStore()
const route = useRoute()
const { t } = useI18n()

const headers = useRequestHeaders(['user-agent'])
const userAgent = headers['user-agent'] || ''

onServerPrefetch(async () => {
  const rootStore = useRootStore()
  const localeStore = useLocaleStore()
  const { isCharter } = localeStore
  const whitelabelStore = useWhitelabelStore()
  if(isCharter && route.query?.wl) {
    whitelabelStore.SET_WHITE_LABEL(route.query?.wl)
  }

  await rootStore.handleAgentCookie(route.query?.a)

  const fetchPromises = [
    rootStore.fetchAgentCall(),
    rootStore.fetchTopNavbar(),
    rootStore.fetchFooter(),
    rootStore.fetchAlert()
  ]

  if (!isMobileUserAgent(userAgent) && !localeStore.isCharter) {
    fetchPromises.push(rootStore.fetchTripTypesSidebar())
  }

  await Promise.allSettled(fetchPromises)
})

onMounted(async () => {
  if (isMobileUserAgent() && !isCharter) {
    await rootStore.fetchTripTypesSidebar(false)
  }
})

const rootStore = useRootStore()
const whitelabelStore = useWhitelabelStore()

const {
  modalActive,
} = storeToRefs(rootStore)
const { locale, isCharter } = useLocaleStore()

const whitelableColor = computed(() => {
  return whitelabelStore.getWhitelabel ? whitelabelStore.getWhitelabel.primaryColor : '#fecb21'
})
const whitelableTextColor = computed(() => {
  return whitelabelStore.getWhitelabel ? 'white' : 'black'
})

useHead({
  htmlAttrs: {
    lang: () => locale === 'zz' ? 'sv' : locale,
  },
})

useSchemaOrg([
  defineWebSite({
    description: t('homeDescription'),
    inLanguage: locale === 'zz' ? 'sv-SE' : locale === 'no' ? 'nb-NO' : 'sv-SE',
  })
])

const wrapperClass = computed(() => {
  const classes = []

  if (!modalActive.value) {
    classes.push('overflow-clip')
  }

  return classes.join(' ')
})

const loadingColor = computed(() => isCharter ? '#fecb21' : '#065BA2')
</script>

<style lang="scss">
.btn-orange {
  background: v-bind(whitelableColor) !important;
  color: v-bind(whitelableTextColor) !important;
}
</style>
