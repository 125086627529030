export const useWhitelabelStore = defineStore('whitelabel', {
  state: () => ({
    whitelabel: null,
    nazar: {
      name: 'nazar',
      logo: 'logoNazar',
      referrerUrl: 'https://www.nazar.se/',
      primaryColor: '#4CB7CD',
      allowedDestinations: [20, 21],
      market: ['zz'],
      webCode: 'efftpvxv1381'
    },
  }),
  getters: {
    getWhitelabel(state) {
      const whitelabel = state.whitelabel;
      if (whitelabel && Object.prototype.hasOwnProperty.call(state, whitelabel)) {
        return state[whitelabel];
      }
      return null;
    },
  },
  actions: {
    SET_WHITE_LABEL(value) {
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale
      if (Object.prototype.hasOwnProperty.call(this.$state, value) && this.$state[value].market.includes(locale)) {
        this.whitelabel = value
        rootStore.handleAgentCookie(this.$state[value].webCode)
      } else {
        this.whitelabel = null
      }
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWhitelabelStore, import.meta.hot))
}
