const events = {
  // Modal toggles
  showHotelModalRooms: 'modal::open::hotel::rooms',
  closeHotelModalRooms: 'modal::close::hotel::rooms',
  openRolfDropdown: 'bv::dropdown::show',
  hideRolfDropdown: 'bv::dropdown::hide',
  showModal: 'bv::modal::show',
  hideModal: 'bv::modal::hide',
  showTranslationModal: 'modal::open::translation::single',
  showFullPageTranslationModal: 'modal::open::translation::page',

  // Localstorage reactivity
  setFavourites: 'localstorage::set::solresorFavourites',

  // Auth
  authLoggedInSuccess: 'auth::user-logged-in',
  loginWithAction: 'auth::login::with-action',
  userIsInitiated: 'auth::login::user-is-initiated',
  loginModalToggle: 'auth::login::modal',

  // Dealfinder
  dealfinderUpdateCalendarDate: 'dealfinder::calendar::update',
  dealfinderLoading: 'dealfinder::loading',

  // Misc
  packageSearchBarSearch: 'zz::search::package-bar',
  packageSearchFiltersReset: 'zz::filters::reset',
  nuxtIsLoading: 'nuxt::loading',
  pageIsReady: 'onreadystatechange',
  mapboxLoaded: 'mapbox::loaded',
  selectedAirport: 'airport::selected',

  // Cookie consent
  rbcsChange: 'consent::rbcs'
};

const loginWithActionEvent = {
  toggleFavourite: 'toggleFavourite',
};

export default events;

export { events, loginWithActionEvent };
