import { CHARTER_MARKETS } from '@packages/locale/constants/locales.ts'

export default defineNuxtPlugin((nuxtApp) => {
  const isCharter = CHARTER_MARKETS.includes(nuxtApp.$config.public.locale)
  const isRolfs = !isCharter

  return {
    provide: {
      isCharter,
      isRolfs
    }
  }
});