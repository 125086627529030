import { default as _91slug_93iOXpkwSaeFMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93jScRRoVnXxMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93poAIYz8FTvMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93RLezVfuSq8Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93cpKz2iTYe5Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93J94FCrXhAvMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93Gpewv1VnqFMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93wEapBxuQb0Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93pDUSrISbXIMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93GEEb8YgprhMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93bYTgLE0wTOMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_939WiQFqvQYQMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93V51dq5mVO0Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93t5xmgwWo7cMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93dfBFDwmXn7Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_939SjbYI340iMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_938Depr10QQtMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93aH8OgBvxz9Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93IAy2II3mY4Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91tab_93SBl6XPhIfBMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as activities0yyPVgQcP7Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/activities.vue?macro=true";
import { default as callback3gJDPPrCukMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as favouritesK9IXEzabijMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/favourites.vue?macro=true";
import { default as my_45bookingsnwMiYf7VnzMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/my-bookings.vue?macro=true";
import { default as blackweekEJrQ9gexxiMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_933o35N0KHX7Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexNUDZxgf7H0Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as byebyeie4khNwyKvL6Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexehsoHqjAqvMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminuteaQJJCfNxBjMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93a5Bds36nvIMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as index6BefmjTjjlMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as cataloguejsVb8EdMnrMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaint0mk5qZ9QJAMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuell3VGqvns7HAMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93DmpHJSMvoqMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexf6EIwrurUgMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faquIaVh6O4ITMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93jLDMaD1AjmMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexsM7NiU4zHDMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93DAluiR236RMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexFD7R0GNAABMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexX29fSZolmTMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelwN7hyMSSdvMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexUypJ2e5A63Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteQfdgGj65zpMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as map84qkkdeCk2Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as mobileApp8rXiDw2IzfMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mobileApp.vue?macro=true";
import { default as dinbokningBkDffarNclMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletter15JgPPoBehMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineXSZfvXcf2NMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failedVdbtK07MavMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexIgzF6lluqhMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as search7p2j93U21wMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexd0q852rA40Meta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45done0EKvSTXkhFMeta } from "/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelasningar",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93SBl6XPhIfBMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/activities.vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "auth-favourites",
    path: "/medlem/favoriter",
    meta: favouritesK9IXEzabijMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/favourites.vue")
  },
  {
    name: "auth-my-bookings",
    path: "/medlem/mina-bokningar",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/my-bookings.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexehsoHqjAqvMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/sista-minuten",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanj/:name()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanj",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: cataloguejsVb8EdMnrMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93t5xmgwWo7cMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaint0mk5qZ9QJAMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_939WiQFqvQYQMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93DAluiR236RMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93GEEb8YgprhMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93wEapBxuQb0Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppresor",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelasningar/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/karta",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mobileApp",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mobileApp.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93J94FCrXhAvMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93cpKz2iTYe5Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93poAIYz8FTvMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45done0EKvSTXkhFMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-11-0754/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]