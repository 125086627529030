
export const DEFAULT_PAGEWIDE_TRANSLATION = {
  [translationTypes.i18n]: [],
  [translationTypes.translation]: [],
  translationData: null
}

export const useLocaleStore = defineStore('locale', {
  state: () => {
    const { public: { locale, DEFAULT_LOCALE, LOCALES, CHARTER_MARKETS, localeURLs } } = useRuntimeConfig()

    return {
      locale: locale || DEFAULT_LOCALE,
      urls: { ...localeURLs },
      locales: LOCALES,
      messages: null,
      localeFallback: DEFAULT_LOCALE,
      countries: [],
      commonCountries: [],
      defaultCountry: null,
      translationToolsActive: false,
      pageWideTranslations: DEFAULT_PAGEWIDE_TRANSLATION,
      charterMarkets: CHARTER_MARKETS,
    }
  },
  getters: {
    getTemporaryFallbackLocale (state) {
      return this.isCharter ? 'sv' : state.locale;
    },

    getLocaleLanguage (state) {
      return localeToLang(state.locale)
    },

    getDefaultCountry (state){
      return state.countries.find((c) => c.key === state.defaultCountry)
    },

    localeURLs (state) {
      return state.urls
    },
    isCharter (state) {
      return state.charterMarkets.includes(state.locale)
    },
    isRolfSwe (state) {
      return state.locale === 'sv'
    },

    washExternalLinkToInternal: (state) => (url, full = false) => {
      const root = state.urls?.BASE_URL;
      return url.replace(`${root}${full ? '/' : ''}`, '');
    },

    getLocale (state) {
      return state.locale
    },

    getTranslationKey: (state) => (key) => state.translationKeys?.[key] || null,

    getTranslationToolsActive (state) {
      return state?.translationToolsActive
    },

    fullTranslationSuiteActive (state) {
      return state.translationToolsActive && !this.isCharter
    },
    isSwedish (state) {
      return state.locale === 'sv' || state.locale === 'zz'
    },

    hasLocaleOpenPurchase (state) {
      return state.locale === 'zz'
    },

    canLocaleLogin (state) {
      return !['zz', 'no'].includes(state.locale)
    } ,
    canLocaleFavorite (state) {
      return !['no'].includes(state.locale)
    },
    hasLocaleAirportSpecificNewsletter: state => state.locale === 'zz',

    getLocaleMessages: state => {
      return state.messages?.[state.locale] || {}
    }
  },
  actions: {
    SET_LOCALE(locale) {
      this.locale = locale
    },

    SET_LOCALE_MESSAGES(messages) {
      this.messages = messages

      const countries = JSON.parse(messages[this.locale].countryCodeList)

      this.countries = countries.list
      this.defaultCountry = countries.default
      this.commonCountries = countries.common
    },

    TOGGLE_TRANSLATION_TOOLS(data) {
      this.translationToolsActive = data !== undefined ? data : !this.translationToolsActive
    },

    SET_PAGE_WIDE_TRANSLATIONS(data) {
      this.pageWideTranslations = {
        ...DEFAULT_PAGEWIDE_TRANSLATION,
        ...data
      }
    },

    async fetchLocaleStrings() {
      const { data: result } = await apiFetch(`/${this.locale}/locale`)

      Object.entries(result).filter(([k, r]) => {
        if (!r) {
          delete result[k]
        }
      })

      const messages = {}
      messages[this.locale] = result

      Object.freeze(messages)

      this.SET_LOCALE_MESSAGES(messages)
    },

    async fetchTranslationKey({ key, type }) {
      try {
        const { data: result } = await apiFetch(`/${this.locale}/${type}/original/${key}`)

        if (result) {
          return result
        }
      } catch (e) {
        const { $sentryCaptureMessage, $sentrySetContext } = useNuxtApp()

        $sentrySetContext({
          'translation_key': key,
          'error': JSON.stringify(e)
        })
        $sentryCaptureMessage('Error fetching translation', {
          level: 'error',
          tags: { type: 'translation' }
        })
        return null
      }
    },

    async updateTranslation({ type, payload }) {
      try {
        const { data: result } = await apiFetch(`/${this.locale}/${type}/update`, {
            method: 'POST',
            body: payload
            })

        if (result) {
          return result
        }
      } catch (e) {
        console.error('Failed updating translation: ', payload)
        return null
      }
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLocaleStore, import.meta.hot))
}
